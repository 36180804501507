import React from 'react';
import { getPortfolioItem } from '../../api/portfolio/PortfolioData';

import { Container } from 'react-bootstrap';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DsnGrid from '../../components/DsnGrid';

//--> Component
import HeaderHalf from '../../components/header/HeaderHalf';
import TitleCover from '../../components/heading/title-cover/TitleCover';
import FadeUpTrigger from '../../animation/FadeUpTrigger';
import TextTrigger from '../../animation/TextTrigger';
import ButtonProject from '../../components/button/button-project/ButtonProject';
import BoxGallery, {
  BoxGalleryItem,
} from '../../components/box-gallery/BoxGallery';
import ButtonDefault from '../../components/button/button-default/ButtonDefault';
import BoxImageVertical from '../../components/box-image-vertical/BoxImageVertical';
import ParallaxImage from '../../components/parallax-image/ParallaxImage';
import ButtonPopup from '../../components/button/button-popup/ButtonPopup';
import MoveBox from '../../components/move-box/MoveBox';
import FJustifiedGallery from '../../components/justified-gallery/FJustifiedGallery';
import NextProject from '../../components/next/NextProject';
import { Helmet } from 'react-helmet';

const EventosView = ({ data }) => {
  const nextData = getPortfolioItem(2);
  data = getPortfolioItem(1);
  return (
    <React.Fragment>
      <Helmet>
        <title>Doulfikar - {data.title} </title>
        <meta name="description" content={data.description} />
      </Helmet>
      {/*Start Header Half*/}
      <HeaderHalf
        heroContent={data}
        parallax={{ yPercent: 30, scale: 1.1 }}
        textButton="Contactanos"
        href="https://www.doulfikar.com/contact"
        overlay={data.overlay}
      >
        <strong className="color-heading">Abdellah Doulfikar</strong>{' '}
        <span> - September 26th 2017</span>
      </HeaderHalf>
      {/*End Header Half*/}

      {/*Start Info Project*/}
      <Container className="section-margin">
        <TitleCover>Servicios Fotográficos</TitleCover>
        <TextTrigger duration={0.8} stagger={0.1}>
          {(ref) => (
            <h2 className="title-section" ref={ref}>
              Capturamos la esencia del momento
            </h2>
          )}
        </TextTrigger>
        <FadeUpTrigger stagger={0.5}>
          <p className="mt-20">
            En cada ocasión especial, los momentos preciados merecen ser
            capturados con la magia que solo la fotografía puede ofrecer. Ya sea
            una boda, un bautizo, una comunión, una gala u otro evento
            significativo, nuestro equipo de fotógrafos expertos está listo para
            capturar la esencia misma de cada momento. Nos enorgullece ofrecer
            un servicio personalizado que se adapta a tus necesidades y
            expectativas, asegurando que cada imagen cuente la historia única de
            tu evento.
          </p>
        </FadeUpTrigger>
      </Container>
      {/*End Info Project*/}

      {/*Start Box Info With Image*/}
      <BoxImageVertical
        src="/assets/img/eventosFinal/eventos_2.jpeg"
        className="section-margin"
      >
        <FadeUpTrigger>
          {(ref) => (
            <DsnGrid col={1} rowGap={30} rowGapTablet={20}>
              <TitleCover>Doulfikar Photography</TitleCover>
              <h2 className="title-section border-line-left" ref={ref}>
                Compromiso con la belleza y la simetria
              </h2>
              <h6 ref={ref}>
                En Doulfikar Photography comprendemos la importancia de los
                momentos por ello no nos basta con solo capturar imágenes;
                buscamos crear imagenes que perduren los recuerdos y las
                emociones en ellas vivida para toda la vida.
              </h6>
              <p ref={ref}>
                Con la pasión y la dedicación que ponemos en cada disparo,
                puedes confiar en nosotros para preservar los momentos más
                preciados de tu evento de una manera auténtica y conmovedora.
              </p>
              <div className="button-box" ref={ref}>
                <ButtonDefault
                  text="Contactanos"
                  icon={faAngleRight}
                  toContact={true}
                />
              </div>
            </DsnGrid>
          )}
        </FadeUpTrigger>
      </BoxImageVertical>
      {/*End  Box Info With Image*/}

      {/*Start Box Info Move Content*/}
      <div className="p-relative section-margin">
        <ParallaxImage
          src="/assets/img/eventosFinal/eventos_4.jpeg"
          overlay={4}
        />
        <MoveBox>
          <h3>
            {' '}
            Disfruta del momento, <br /> Te cubrimos las espaldas{' '}
          </h3>
          <p className="mt-30">
            Estando relajado y siendo espontaneos es como se consiguen las
            mejores imagenes, y es por eso que le damos tanta importancia a la
            comunicación y a tu comodida, sueltate el pelo, que nosotros
            estremos ahi para capturar los momentos más inolvidable por ti{' '}
          </p>
        </MoveBox>
      </div>
      {/*End Box Info Move Content*/}

      <FJustifiedGallery
        images={[
          {
            src: '/assets/img/eventosFinal/b.jpg',

            caption: '',
          },
          {
            src: '/assets/img/eventosFinal/c.jpg',

            caption: '',
          },
          {
            src: '/assets/img/eventosFinal/d.jpeg',

            caption: '',
          },
          {
            src: '/assets/img/eventosFinal/e.jpg',

            caption: '',
          },
          {
            src: '/assets/img/eventosFinal/f.jpg',

            caption: '',
          },
        ]}
        className="section-margin"
      />

      <NextProject heroContent={nextData} overlay={nextData.overlay} />
    </React.Fragment>
  );
};

export default EventosView;
