import React from 'react';
import { getPortfolioItem } from '../../api/portfolio/PortfolioData';

import { Container } from 'react-bootstrap';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DsnGrid from '../../components/DsnGrid';

//--> Component
import HeaderHalf from '../../components/header/HeaderHalf';
import TitleCover from '../../components/heading/title-cover/TitleCover';
import FadeUpTrigger from '../../animation/FadeUpTrigger';
import TextTrigger from '../../animation/TextTrigger';
import ButtonProject from '../../components/button/button-project/ButtonProject';
import BoxGallery, {
  BoxGalleryItem,
} from '../../components/box-gallery/BoxGallery';
import ButtonDefault from '../../components/button/button-default/ButtonDefault';
import BoxImageVertical from '../../components/box-image-vertical/BoxImageVertical';
import ParallaxImage from '../../components/parallax-image/ParallaxImage';
import ButtonPopup from '../../components/button/button-popup/ButtonPopup';
import MoveBox from '../../components/move-box/MoveBox';
import FJustifiedGallery from '../../components/justified-gallery/FJustifiedGallery';
import NextProject from '../../components/next/NextProject';
import { Helmet } from 'react-helmet';

const NaturalezaView = ({ data }) => {
  const nextData = getPortfolioItem(4);
  data = getPortfolioItem(3);
  return (
    <React.Fragment>
      <Helmet>
        <title>Doulfikar - {data.title} </title>
        <meta name="description" content={data.description} />
      </Helmet>
      {/*Start Header Half*/}
      <HeaderHalf
        heroContent={data}
        parallax={{ yPercent: 30, scale: 1.1 }}
        textButton="Contactanos"
        href="https://www.doulfikar.com/contact"
        overlay={data.overlay}
      >
        <strong className="color-heading">Dufli Doulfikar</strong>{' '}
        <span> - September 26th 2017</span>
      </HeaderHalf>
      {/*End Header Half*/}

      {/*Start Info Project*/}
      <Container className="section-margin">
        <TitleCover>Servicios Fotográficos</TitleCover>
        <TextTrigger duration={0.8} stagger={0.1}>
          {(ref) => (
            <h2 className="title-section" ref={ref}>
              Captura la Esencia de la Naturaleza
            </h2>
          )}
        </TextTrigger>
        <FadeUpTrigger stagger={0.5}>
          <p className="mt-20">
            En el efímero escenario de la naturaleza, cada momento es único,
            cada instante es irrepetible. ¿Qué mejor manera de atesorar esas
            memorias que con imágenes que capturan la esencia misma de tu
            experiencia? Bienvenido a Doulfikar Photography, donde transformamos
            tus momentos en recuerdos eternos con la magia de la fotografía. Ya
            sea un romántico paseo por el bosque, una escapada en familia junto
            al río, o una aventura solitaria en la montaña, en Doulfikar
            Photography entendemos la importancia de cada ocasión. Nos
            esforzamos por superar tus expectativas, capturando no solo
            imágenes, sino también momentos que perdurarán para siempre en tu
            corazón.
          </p>
        </FadeUpTrigger>
      </Container>
      {/*End Info Project*/}

      {/*Start Box Info With Image*/}
      <BoxImageVertical
        src="/assets/img/naturalezaFinal/naturaleza_1.jpg"
        className="section-margin"
      >
        <FadeUpTrigger>
          {(ref) => (
            <DsnGrid col={1} rowGap={30} rowGapTablet={20}>
              <TitleCover>Doulfikar Photography</TitleCover>
              <h2 className="title-section border-line-left" ref={ref}>
                ¿Por qué conformarse con fotografías ordinarias?
              </h2>
              <h6 ref={ref}>
                Con Doulfikar Photography, cada clic de la cámara es una
                invitación a revivir tus momentos más preciados una y otra vez.
              </h6>
              <p ref={ref}>
                El proceso de solicitud de servicios fotográficos en parques
                naturales implica una investigación de la propuesta y los
                requerimientos, posteriormente la confirmación, preparación y
                cobertura del evento y finalmente la entrega de imágenes y
                seguimiento. Este proceso garantiza una experiencia
                satisfactoria.
              </p>
              <div className="button-box" ref={ref}>
                <ButtonDefault
                  text="Contanta con nosotros"
                  icon={faAngleRight}
                  toContact={true}
                />
              </div>
            </DsnGrid>
          )}
        </FadeUpTrigger>
      </BoxImageVertical>
      {/*End  Box Info With Image*/}

      {/*Start Box Info Move Content*/}
      <div className="p-relative section-margin">
        <ParallaxImage
          src="/assets/img/naturalezaFinal/naturaleza.jpg"
          overlay={4}
        />
        <MoveBox>
          <h3>
            {' '}
            Toma Asiento y
            <br /> Disfruta de Tus Imágenes{' '}
          </h3>
          <p className="mt-30">
            Con la ayuda de la inteligencia artificial, Doulfikar Photography
            lleva la experiencia fotográfica en la naturaleza a un nivel
            completamente nuevo. Nuestro innovador enfoque combina la pasión por
            la fotografía con las capacidades vanguardistas de la inteligencia
            artificial para garantizar que cada imagen capturada sea una obra
            maestra
          </p>
        </MoveBox>
      </div>
      {/*End Box Info Move Content*/}

      <FJustifiedGallery
        images={[
          {
            src: '/assets/img/naturalezaFinal/naturaleza_3.jpg',
            caption: 'TIME TAG WATCH',
          },
          {
            src: '/assets/img/naturalezaFinal/naturaleza_5.jpg',
            caption: 'TIME TAG WATCH',
          },
          {
            src: '/assets/img/naturalezaFinal/naturaleza_4.jpg',
            caption: 'TIME TAG WATCH',
          },
          {
            src: '/assets/img/naturalezaFinal/naturaleza_7.jpg',
            caption: 'TIME TAG WATCH',
          },
          {
            src: '/assets/img/naturalezaFinal/naturaleza_6.jpg',
            caption: 'TIME TAG WATCH',
          },

          {
            src: '/assets/img/naturalezaFinal/naturaleza_8.jpg',
            caption: 'TIME TAG WATCH',
          },
        ]}
        className="section-margin"
      />

      <NextProject heroContent={nextData} overlay={nextData.overlay} />
    </React.Fragment>
  );
};

export default NaturalezaView;
