import React from 'react';
import { dsnCN } from '../../../hooks/helper';

function MenuContent({ className }) {
  const socialData = [
    { link: 'http://wa.me/+34658237914', name: 'Whatsapp.' },
    { link: 'https://www.facebook.com/dulfi.doulfikar', name: 'Facebook.' },
    { link: 'https://www.instagram.com/dulfi_doulfikar/', name: 'Instagrm.' },
  ];
  return (
    <div
      className={dsnCN(
        'container-content  d-flex flex-column justify-content-center',
        className
      )}
    >
      <div className="nav__info">
        <div className="nav-content">
          <h5 className="sm-title-block mb-10">Studio</h5>
          <p>
            <a
              href="https://www.google.com/maps/place/Dulfi+Fotografia/@37.2560827,-6.9560363,15z/data=!4m6!3m5!1s0xd11d1e3d4492ac9:0x121d52fbddbf3c7c!8m2!3d37.2560827!4d-6.9560363!16s%2Fg%2F11q25057by?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              C. Marina, 19,
              <br /> 21001 Huelva, España
            </a>
          </p>
        </div>
        <div className="nav-content mt-30">
          <h5 className="sm-title-block mb-10">Contacto</h5>
          <p className="links over-hidden mb-1">
            <a href="tel:+34658237914" target="_blank" rel="noreferrer">
              +34 658 23 79 14
            </a>
          </p>
          <p className="links over-hidden">
            <a
              href="mailto:huelvafoto@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              info@doulfikar.com
            </a>
          </p>
        </div>
      </div>
      <div className="nav-social nav-content mt-30">
        <div className="nav-social-inner p-relative">
          <h5 className="sm-title-block mb-10">Follow us</h5>
          <ul>
            {socialData.map((item, index) => (
              <li key={index}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenuContent;
