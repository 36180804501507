import EventosView from '../../views/portfolio/EventosView';
import RetratosView from '../../views/portfolio/RetratosView';
import NaturalezaView from '../../views/portfolio/NaturalezaView';
import SocialView from '../../views/portfolio/SocialView';

const data = [
  {
    id: 1,
    title: 'Eventos',
    slug: 'events',
    category: ['eventos'],
    description:
      'Ya sea una boda, una fiesta de cumpleaños o una conferencia corporativa, estamos aquí para documentar cada momento especial, capturamos por ti recuerdos que perdurarán para siempre.',
    src: '/assets/img/imagenes_categorias/portada_eventos.jpg',
    srcSlider: '/assets/img/imagenes_categorias/portada_eventos.jpg',
    overlay: 2,
    component: (props) => <EventosView {...props} />,
  },
  {
    id: 2,
    title: 'Retratos',
    slug: 'portrait',
    src: '/assets/img/imagenes_categorias/portada_retratos.jpg',
    srcSlider: '/assets/img/imagenes_categorias/portada_retratos.jpg',
    category: ['retratos'],
    description:
      'Buscamos crear retratos que reflejen tu personalidad y estilo individual. Ya sea para uso profesional o personal, trabajaremos contigo para crear imágenes que te hagan sentir desde seguro y hermoso.',
    overlay: 2,

    component: (props) => <RetratosView {...props} />,
  },
  {
    id: 3,
    title: 'Naturaleza',
    slug: 'nature',
    src: '/assets/img/imagenes_categorias/portada_naturaleza.jpg',
    srcSlider: '/assets/img/imagenes_categorias/portada_naturaleza.jpg',
    category: ['naturaleza'],
    description:
      'Sumérgete en la belleza del mundo natural a través de nuestras imágenes de paisajes y vida silvestre. En Doulfikar Photography, exploramos la majestuosidad de la naturaleza en cada foto, desde imponentes montañas hasta delicadas flores.',
    overlay: 2,
    component: (props) => <NaturalezaView {...props} />,
  },
  {
    id: 4,
    title: 'Fotografia Social',
    slug: 'social',
    category: ['social'],
    src: '/assets/img/imagenes_categorias/portada_social.jpg',
    srcSlider: '/assets/img/imagenes_categorias/portada_social.jpg',
    description:
      'Las fotografías son una poderosa herramienta para contar historias y crear conciencia sobre temas sociales importantes por ello nos comprometemos a utilizar nuestras lentes para documentar realidades, destacar causas humanitarias y dar voz a aquellos que a menudo son ignorados. Desde proyectos comunitarios hasta reportajes fotográficos, trabajamos con empatía y respeto para compartir historias que inspiren el cambio.',
    overlay: 2,
    component: (props) => <SocialView {...props} />,
  },
  /*
  {
    id: 5,
    title: 'Sobre nosotros',
    slug: 'conocenos',
    src: '/assets/img/papa_portada3.jpg',
    srcSlider: '/assets/img/papa_portada3.jpg',
    category: ['conocenos'],
    description:
      'En Doulfikar Photography, somos más que fotógrafos; somos narradores visuales apasionados. Con años de experiencia en la industria, nuestro equipo combina habilidad técnica con una visión artística para crear imágenes que emocionan, inspiran y perduran en el tiempo. Nos comprometemos a superar tus expectativas y a capturar momentos que atesorarás para siempre. Conócenos y déjanos ser parte de tu historia',
    overlay: 2,
    component: (props) => <WhatIfGold {...props} />,
  },
 
    {
    id: 6,
    title: 'Fotografia Social',
    slug: 'social',
    category: ['social'],
    src: 'https://theme.dsngrid.com/video/droow.mp4',
    video: {
      poster: '/assets/img/project/project4/1.jpg',
      loop: true,
    },
    description:
      'We are thrilled to share our new reel with you all! Special thanks to all of our talented friends.',
    overlay: 2,
    component: (props) => <ToastReel {...props} />,
  },
    {
        id: 7,
        title: "Sleep Walker",
        slug: 'sleep-walker',
        category: ['Photography'],
        src: '/assets/img/project/project7/1.jpg',
        srcSlider: '/assets/img/project/project7/4.jpg',
        description: 'Cal was first. The first public university in the great state of California. They are the pioneers. They are the trailblazers who started it all.',
        overlay: 2,
        component: (props) => <SleepWalker {...props}/>
    },
    {
        id: 8,
        title: "Novara Conic",
        slug: 'novara-conic',
        src: '/assets/img/project/project8/1.jpg',
        srcSlider: '/assets/img/project/project8/4.jpg',
        category: ['Architecture'],
        description: 'Cal was first. The first public university in the great state of California. They are the pioneers.',
        overlay: 2,
        component: (props) => <NovaraConic {...props}/>
    },
    {
        id: 9,
        title: "Bastian Bux",
        slug: 'bastian-bux',
        category: ['Photography'],
        description: 'Bastian bux is the consequence of reducing everything surrounding a dj and producer to its essential element: the music.',
        src: '/assets/img/project/project9/1.jpg',
        srcSlider: '/assets/img/project/project9/3.jpg',
        overlay: 2,
        component: (props) => <BastianBux {...props}/>
    },
    */
];

export const getPortfolioData = () => data;

export const getPortfolioItem = (value, whereName = 'id') => {
  return data.find((item) => {
    if (item[whereName] === value) return item;
    return null;
  });
};
export const getPortfolioLink = (item) => {
  if (item) return item.slug && '/portfolio/' + item.slug;

  return '';
};
