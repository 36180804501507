import React from 'react';
import { getPortfolioItem } from '../../api/portfolio/PortfolioData';

import { Container } from 'react-bootstrap';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DsnGrid from '../../components/DsnGrid';

//--> Component
import HeaderHalf from '../../components/header/HeaderHalf';
import TitleCover from '../../components/heading/title-cover/TitleCover';
import FadeUpTrigger from '../../animation/FadeUpTrigger';
import TextTrigger from '../../animation/TextTrigger';
import ButtonProject from '../../components/button/button-project/ButtonProject';
import BoxGallery, {
  BoxGalleryItem,
} from '../../components/box-gallery/BoxGallery';
import ButtonDefault from '../../components/button/button-default/ButtonDefault';
import BoxImageVertical from '../../components/box-image-vertical/BoxImageVertical';
import ParallaxImage from '../../components/parallax-image/ParallaxImage';
import ButtonPopup from '../../components/button/button-popup/ButtonPopup';
import MoveBox from '../../components/move-box/MoveBox';
import FJustifiedGallery from '../../components/justified-gallery/FJustifiedGallery';
import NextProject from '../../components/next/NextProject';
import { Helmet } from 'react-helmet';

const RetratosView = ({ data }) => {
  const nextData = getPortfolioItem(3);
  data = getPortfolioItem(2);
  return (
    <React.Fragment>
      <Helmet>
        <title>Doulfikar - {data.title} </title>
        <meta name="description" content={data.description} />
      </Helmet>
      {/*Start Header Half*/}
      <HeaderHalf
        heroContent={data}
        parallax={{ yPercent: 30, scale: 1.1 }}
        textButton="View Website"
        href="https://www.doulfikar.com/contact"
        overlay={data.overlay}
      >
        <strong className="color-heading">Abdellah Doulfikar</strong>{' '}
        <span> - September 26th 2017</span>
      </HeaderHalf>
      {/*End Header Half*/}

      {/*Start Info Project*/}
      <Container className="section-margin">
        <TitleCover>Servicios forográficos</TitleCover>
        <TextTrigger duration={0.8} stagger={0.1}>
          {(ref) => (
            <h2 className="title-section" ref={ref}>
              Damos un nuevo significado a la fotografía corporal
            </h2>
          )}
        </TextTrigger>
        <FadeUpTrigger stagger={0.5}>
          <p className="mt-20">
            Nuestra pasión por la búsqueda de la esencia nos ha llevado a
            explorar nuevas fronteras en el mundo de la fotografía corporal,
            trascendiendo los límites de lo convencional para capturar lo que te
            define y te hace especial.
          </p>
        </FadeUpTrigger>
      </Container>
      {/*End Info Project*/}

      {/*Start Box Info With Image*/}
      <BoxImageVertical
        src="/assets/img/retratoFinal/retratos_1.jpeg"
        className="section-margin"
      >
        <FadeUpTrigger>
          {(ref) => (
            <DsnGrid col={1} rowGap={30} rowGapTablet={20}>
              <TitleCover>Servicios Fotográficos</TitleCover>
              <h2 className="title-section border-line-left" ref={ref}>
                Contamos tu historia
              </h2>
              <h6 ref={ref}>
                Mas del 65% de la comunicación es no verbal, una mirada, unos
                labios...
              </h6>
              <p ref={ref}>
                Nos encargamos de entender tu visión y objetivos ....
              </p>
              <div className="button-box" ref={ref}>
                <ButtonDefault
                  text="launch"
                  icon={faAngleRight}
                  toContact={true}
                />
              </div>
            </DsnGrid>
          )}
        </FadeUpTrigger>
      </BoxImageVertical>
      {/*End  Box Info With Image*/}

      {/*Start Box Info Move Content*/}
      <div className="p-relative section-margin">
        <ParallaxImage
          src="/assets/img/retratoFinal/retrato2_1.png"
          overlay={4}
        />
        <MoveBox>
          <h3> ¿Cansado de reportajes sin emoción? </h3>
          <h7>
            Con Doulfikar Photography, cada clic de la cámara es una invitación
            a observar nuevas dimensiones de ti, que incluso tú desconocias
          </h7>
        </MoveBox>
      </div>
      {/*End Box Info Move Content*/}

      <FJustifiedGallery
        images={[
          {
            src: '/assets/img/socialFinal/a.jpeg',
          },
          {
            src: '/assets/img/socialFinal/b.jpg',
          },
          {
            src: '/assets/img/socialFinal/c.jpg',
          },
          {
            src: '/assets/img/socialFinal/d.jpg',
          },
          {
            src: '/assets/img/socialFinal/e.jpg',
          },
          {
            src: '/assets/img/socialFinal/f.jpg',
          },
        ]}
        className="section-margin"
      />

      <NextProject heroContent={nextData} overlay={nextData.overlay} />
    </React.Fragment>
  );
};

export default RetratosView;
