import React from 'react';
import { getPortfolioItem } from '../../api/portfolio/PortfolioData';

import { Container } from 'react-bootstrap';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DsnGrid from '../../components/DsnGrid';

//--> Component
import HeaderHalf from '../../components/header/HeaderHalf';
import TitleCover from '../../components/heading/title-cover/TitleCover';
import FadeUpTrigger from '../../animation/FadeUpTrigger';
import TextTrigger from '../../animation/TextTrigger';
import ButtonProject from '../../components/button/button-project/ButtonProject';
import BoxGallery, {
  BoxGalleryItem,
} from '../../components/box-gallery/BoxGallery';
import ButtonDefault from '../../components/button/button-default/ButtonDefault';
import BoxImageVertical from '../../components/box-image-vertical/BoxImageVertical';
import ParallaxImage from '../../components/parallax-image/ParallaxImage';
import ButtonPopup from '../../components/button/button-popup/ButtonPopup';
import MoveBox from '../../components/move-box/MoveBox';
import FJustifiedGallery from '../../components/justified-gallery/FJustifiedGallery';
import NextProject from '../../components/next/NextProject';
import { Helmet } from 'react-helmet';

const SocialView = ({ data }) => {
  const nextData = getPortfolioItem(1);
  data = getPortfolioItem(4);
  return (
    <React.Fragment>
      <Helmet>
        <title>Doulfikar - {data.title} </title>
        <meta name="description" content={data.description} />
      </Helmet>
      {/*Start Header Half*/}
      <HeaderHalf
        heroContent={data}
        parallax={{ yPercent: 30, scale: 1.1 }}
        textButton="Contactanos"
        href="https://www.doulfikar.com/contact"
        overlay={data.overlay}
      >
        <strong className="color-heading">Abdellah Doulfikar</strong>{' '}
        <span> - September 26th 2017</span>
      </HeaderHalf>
      {/*End Header Half*/}

      {/*Start Info Project*/}
      <Container className="section-margin">
        <TitleCover>Valores</TitleCover>
        <TextTrigger duration={0.8} stagger={0.1}>
          {(ref) => (
            <h2 className="title-section" ref={ref}>
              Compromiso con la responsabilidad social
            </h2>
          )}
        </TextTrigger>
        <FadeUpTrigger stagger={0.5}>
          <p className="mt-20">
            En Doulfikar Photography, creemos que la responsabilidad social es
            más que una obligación; es un valor fundamental que guía cada
            aspecto de nuestro trabajo. Nos enorgullece asociarnos con empresas
            que comparten nuestros principios y están comprometidas con hacer
            del mundo un lugar mejor.
          </p>
        </FadeUpTrigger>
      </Container>
      {/*End Info Project*/}

      {/*Start Box Info With Image*/}
      <BoxImageVertical
        src="/assets/img/socialFinal/social_1.jpg"
        className="section-margin"
      >
        <FadeUpTrigger>
          {(ref) => (
            <DsnGrid col={1} rowGap={30} rowGapTablet={20}>
              <TitleCover>Tus Valores</TitleCover>
              <h2 className="title-section border-line-left" ref={ref}>
                Si estás buscando
              </h2>
              <h6 ref={ref}>
                Un socio de confianza que comparta tus valores y refleje la
                esencia de tu empresa a través de la fotografía, no busques más.
              </h6>
              <p ref={ref}>
                Contáctanos y descubre cómo podemos ayudarte a transmitir tu
                mensaje de responsabilidad social de una manera auténtica y
                poderosa.
              </p>
              <div className="button-box" ref={ref}>
                <ButtonDefault
                  text="Contactanos"
                  icon={faAngleRight}
                  toContact={true}
                />
              </div>
            </DsnGrid>
          )}
        </FadeUpTrigger>
      </BoxImageVertical>
      {/*End  Box Info With Image*/}

      <FJustifiedGallery
        images={[
          {
            src: '/assets/img/socialFinal/aaa.jpg',
          },
          {
            src: '/assets/img/socialFinal/aa.jpg',
          },
          {
            src: '/assets/img/socialFinal/ab.jpg',
          },
          {
            src: '/assets/img/socialFinal/ac.jpg',
          },
          {
            src: '/assets/img/socialFinal/ad.jpg',
          },
          {
            src: '/assets/img/socialFinal/ae.jpg',
          },
        ]}
        className="section-margin"
      />

      <NextProject heroContent={nextData} overlay={nextData.overlay} />
    </React.Fragment>
  );
};

export default SocialView;
